<template>
  <div class="list">
    <page-content
      :contentTableConfig="contentTableConfig"
      :isButtonStyle="true"
      :dataCount="memberInfoCount"
      :dataList="dataList"
    >
      <template #items="scope">
        <template v-if="scope.row.items != null && scope.row.items.length > 0">
          {{ scope.row.items[0]["itemName"] }}
        </template>
        <template v-else> - </template>
      </template>

      <template #tichengQty="scope">
        {{ (scope.row.tichengQty / 100).toFixed(2) }}
      </template>
    </page-content>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { contentTableConfig } from '../../config'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { testTicheng } from '@/service/main/base'
export default defineComponent({
  props: {

  },
  components: {
    PageContent
  },
  setup() {

    const dataList = ref([])
    const handleCount=item=>{
      // console.log(item);
    }

    const initPage = async () => {
      const res = await testTicheng()
      dataList.value = res.data.list
    }
    initPage()

    return {
      dataList,
      contentTableConfig,
      handleCount
    }

  }
})
</script>

<style scoped lang="less">
.list {
}
</style>
